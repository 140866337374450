import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Books, Pagination } from "../../components"

const BooksPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: books },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Books title="1001 Könyv" books={books} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-light-3);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query BooksQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "Books" } }
      limit: $limit
      skip: $skip
      sort: { fields: data___books_title, order: ASC }
    ) {
      nodes {
        id
        recordId
        data {
          books_title
          books_excerpt
          books_author
          books_row
          books_text {
            childMarkdownRemark {
              html
            }
          }
          books_image {
            localFiles {
              childImageSharp {
                fluid {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BooksPage
